import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";

import PageLayout from "../components/page-layout";
import AutoCompleteInput from "../components/autocomplete-input";
import StarRating from "../components/star-rating";

import * as style from "./submit-feedback.module.css";

function FormInput({
  type,
  label,
  name,
  value,
  onChange,
  withRating,
  options,
  from,
  to,
  ...rest
}) {
  const [starRating, setStarRating] = useState(0);

  const id = `_ff_${name}`;

  const Input = type === "textarea" ? "textarea" : "input";

  const input =
    type === "select" ? (
      <select
        {...{ id, type, name, [onChange ? "value" : "defaultValue"]: value }}
      >
        {(options || []).map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    ) : (
      <Input
        {...rest}
        {...{ id, type, name, [onChange ? "value" : "defaultValue"]: value }}
        onChange={
          onChange
            ? (event) => {
                onChange(event.target.value);
              }
            : null
        }
      />
    );

  return (
    <div className={style.formElement}>
      <label htmlFor={id}>{label}</label>
      {withRating && (
        <div>
          <div className={style.ratingContainer}>
            <div>
              {from && <span className={style.rangeStart}>{from}</span>}
              {to && <span className={style.rangeEnd}>{to}</span>}
            </div>
            <StarRating
              value={starRating}
              onChange={setStarRating}
              containerClassName={style.starContainer}
            />
            <input
              type="hidden"
              defaultValue={starRating}
              name={`${name}_numeric`}
            />
          </div>
        </div>
      )}
      {input}
    </div>
  );
}

async function fetchCompanies() {
  const response = await fetch("/api/v1/companies");
  if (!response.ok) throw new Error(response.statusText);
  return response.json();
}

export default function DiversityPage({ data }) {
  const [companies, setCompanies] = useState([]);
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    (async () => {
      const result = await fetchCompanies();
      setCompanies(result);
    })().catch(console.error);
  }, []);

  return (
    <PageLayout navProps={{ hasHeight: true }}>
      <section>
        <h1>Tell us about your company</h1>
        <h2>
          Let other diverse professionals know what your workplace feels like...
        </h2>

        <p>
          Your answers are absolutely anonymous. We never store any personal
          information, your IP address or any other information that could help
          identify you. If your position in your company could identify you,
          please leave it blank.
        </p>

        <form
          action="/api/v1/feedback"
          method="POST"
          encType="multipart/form-data"
        >
          <div className={style.formElement}>
            <label htmlFor="_ff_company_name">Company</label>
            <AutoCompleteInput
              id="_ff_company_name"
              name="company_name"
              value={companyName}
              onChange={setCompanyName}
              getSuggestions={(value) =>
                companies
                  .filter((company) =>
                    company.name.toLowerCase().startsWith(value.toLowerCase())
                  )
                  .map((company) => company.name)
              }
              options={{ highlightPattern: true }}
            />
          </div>

          {/* <FormInput
            type="text"
            name="position"
            label="Your position"
            placeholder="e.g. IT Project Manager"
          /> */}
          <FormInput
            type="select"
            name="minority"
            label="You identify as / You are"
            options={[
              { label: "International Professional", value: "international" },
              { label: "Woman", value: "woman" },
              { label: "Parent", value: "parent" },
              { label: "LGBTQ+", value: "lgbtq+" },
              { label: "Neurodiverse Person", value: "neurodiverse" },
              { label: "Person with disability", value: "disabled" },
              {
                label: "Person with chronical illness (physical or mental)",
                value: "chronical-illness",
              },
              { label: "Other", value: "other" },
            ]}
          />

          <FormInput
            type="textarea"
            name="overall"
            label="How satisfied are/were you with the company's diversity and inclusion?"
            withRating
          />

          {/* <FormInput
            type="textarea"
            name="overall"
            label="How diverse is your company really? Diverse colleagues? Diverse management?"
            withRating
          />
          <FormInput
            type="textarea"
            name="colleagues"
            label="Do your colleagues or superiors contribute to your feeling of belonging? How?"
            withRating
          />
          <FormInput
            type="textarea"
            name="microaggressions"
            label="How often do you experince microagressions?"
            from="Never"
            to="Often"
            withRating
          />
          <FormInput
            type="select"
            options={[
              { label: "German", value: "de" },
              { label: "English", value: "en" },
              { label: "Other", value: "other" },
            ]}
            name="spoken-language"
            label="Language mostly spoken?"
          />
          <FormInput
            type="textarea"
            name="training"
            label="Diversity & inclusion training?"
            withRating
          /> */}
          <button type="submit">Make the world a better place :)</button>
        </form>
      </section>
    </PageLayout>
  );
}

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "bg.svg" }) {
      publicURL
    }
  }
`;
